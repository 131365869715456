import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { removeUserSession } from './Utils/Common';
import Form from "react-bootstrap/Form";
import { API } from 'aws-amplify';

import Menu from "./Utils/Sidebar";
import Popup from "reactjs-popup";
import BurgerIcon from "./Utils/BurgerIcon";

const columns = [
  {
    name: 'Nombres',
    selector: 'nombres',
    sortable: true,
  },
  {
    name: 'Ape. Paterno',
    selector: 'apellidoPaterno',
    sortable: true,
  },
  {
    name: 'Ape. Materno',
    selector: 'apellidoMaterno',
    sortable: true,
  },
  {
    name: 'DNI',
    selector: 'dni',
  },
  {
    name: 'Fecha Ingreso',
    selector: 'fechaIngreso',
    width: "140px"
  },
  {
    name: 'Nro. Hab.',
    selector: 'numeroHabitacion',
  },
  {
    name: 'Precio',
    selector: 'precioHabitacion',
  },
  {
    name: 'Yape/Plim',
    selector: 'isTransferencia',
  }
];

function Reporte(props) {

  const [datos, setDatos] = useState([]);

  const consultarClienteByFecIng = (event) =>{
    // event.preventDefault();   
    const apiname = 'client';
    const path = '/hostal/api/cliente/fecha/' + event.target.value.substring(0,10);

     API.get(apiname, path)
         .then(function (response) {
             console.log(response);
             setDatos(response);
         })
         .catch(function (error) {
             alert('ERROR : ', error);
             console.log(error);
         })
         .then(function () {
             // always executed
            // setLoading(false);
         }); 
   //  }
   }

   const handleLogout = () => {
    removeUserSession();
    props.history.push('/login');
  }

  const contentStyle = {
    position: "fixed",
    transition: "all 0.5s ease 0s"
  };

  return (
    <div className="App">
       <Popup
        modal
        overlayStyle={{ background: "#373a47", right: "inherit", width: "300px", height: "100%",}}
        contentStyle={contentStyle}
        closeOnDocumentClick={false}
        trigger={open => <BurgerIcon open={open} />}
     >
        {close => <Menu close={close} />}
    </Popup>
      <br/>
       <h3 style={{ textAlign: 'center' }}><a href="#">Seleccionar fecha</a></h3>
       <Form>
      <Form.Group size="lg" controlId="fechaIngreso">
      <div className='col_half'>
          <Form.Control
            type="datetime-local"
            onChange={consultarClienteByFecIng}
            required
          />
      </div>
      </Form.Group>
      <Form.Group size="lg" controlId="salir">
      <div className='col_half'>
        <Form.Control
              type="button"
              onClick={handleLogout}
              value="Salir"
              className="btn-text"
        />
      </div>
      </Form.Group>
      </Form>
      <br />
      <div style={{ marginTop: '28px' }}>
      <DataTable
        title="Huéspedes"
        columns={columns}
        data={datos}
        pagination
        highlightOnHover
      />
      </div>
    </div>
  );
}

export default Reporte;