import React, { useState, useEffect } from "react";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";
import Button from "react-bootstrap/Button";

import Form from "react-bootstrap/Form";
import { API } from 'aws-amplify';


function ChartReport (props){

    const [tipoBusqueda, setTipoBusqueda] = useState('CUARTOS_X_DIA');
    const [mes, setMes] = useState(1);
    const [coordenadaX, setCoordenadaX] = useState('DIA');
    const [dataKeyValue, setDataKeyValue] = useState('NRO_CUARTOS');
    const [data, setData] = useState([]);

    const actualizarTipoBusqueda = (event) => {
        switch(event.target.value){
            case 'CUARTOS_X_DIA':
                setCoordenadaX('DIA');
                setDataKeyValue('NRO_CUARTOS');
              break;
        
            case 'RECAUDO_X_DIA':
                setCoordenadaX('DIA');
                setDataKeyValue('INGRESOS');
              break;
            
            case 'CUARTOS_X_SEMANA':
                setCoordenadaX('SEMANA');
                setDataKeyValue('NRO_CUARTOS');
              break;
            
            case 'RECAUDO_X_SEMANA':
                setCoordenadaX('SEMANA');
                setDataKeyValue('INGRESOS');
              break;
        
            case 'CUARTOS_X_MES':
                setCoordenadaX('MES');
                setDataKeyValue('NRO_CUARTOS');
              break;
            
            case 'RECAUDO_X_MES':
                setCoordenadaX('MES');
                setDataKeyValue('INGRESOS');
              break;
          }
        setTipoBusqueda(event.target.value);
    }
  
    const actualizarMes = (event) => {        
        setMes(event.target.value);
    }

    const obtenerReporte = (event) =>{
        const apiname = 'client';
        const path = '/hostal/api/reporte-ventas/' + tipoBusqueda +'/'+mes;
    
         API.get(apiname, path)
             .then(function (response) {
                 console.log(response); 
                 setData(response);
             })
             .catch(function (error) {
                 alert('ERROR : ', error);
                 console.log(error);
             })
             .then(function () {
             }); 
       //  }
       }

       useEffect(() => {
        obtenerReporte();
    
      }, []); 

  return (
   
    <div className="Login">
    <div className='text-center'>
              <h3>
                <strong style={{color: '#e91e63'}}>Reporte de</strong>
                <a href='#!'>
                  <strong> Ventas</strong>
                </a>
              </h3>
              <br />
        <div className='text-center'>
                <h3 style={{color: '#e91e63'}}>
                  <strong></strong>
                </h3>
        </div>
     
    </div>
    <Form>      
      <Form.Group size="lg" controlId="tipoBusqueda">
        <div className='col_half'>
            <Form.Label>Tipo Consulta</Form.Label>
            <Form.Control as="select"  required selected onChange={actualizarTipoBusqueda} value={tipoBusqueda}>
                <option value='CUARTOS_X_DIA' key='CUARTOS_X_DIA'>Cuartos alquilados por día</option>
                <option value='CUARTOS_X_SEMANA' key='CUARTOS_X_SEMANA'>Cuartos alquilados por semana</option>
                <option value='CUARTOS_X_MES' key='CUARTOS_X_MES'>Cuartos alquilados por mes</option>
                <option value='RECAUDO_X_DIA' key='RECAUDO_X_DIA'>Ingresos por día</option>                
                <option value='RECAUDO_X_SEMANA' key='RECAUDO_X_SEMANA'>Ingresos por semana</option>                
                <option value='RECAUDO_X_MES' key='RECAUDO_X_MES'>Ingresos por mes</option>
            </Form.Control>
        </div>
      </Form.Group>
      
      <Form.Group size="lg" controlId="mes">        
        <div className='col_half'>
        <Form.Label>Mes</Form.Label>
        <Form.Control as="select" required onChange={actualizarMes} value={mes}>
            <option value='1' key='1'>Enero</option>
            <option value='2' key='2'>Febrero</option>
            <option value='3' key='3'>Marzo</option>
            <option value='4' key='4'>Abril</option>
            <option value='5' key='5'>Mayo</option>
            <option value='6' key='6'>Junio</option>
            <option value='7' key='7'>Julio</option>
            <option value='8' key='8'>Agosto</option>
            <option value='9' key='9'>Setiembre</option>
            <option value='10' key='10'>Octubre</option>
            <option value='11' key='11'>Noviembre</option>
            <option value='12' key='12'>Diciembre</option>
        </Form.Control>
        
        </div>
        <br/>
        <br/>

      </Form.Group>
      
      <br/>
      <Button block size="lg" type="button" onClick={obtenerReporte}>
        Consultar
      </Button>
      <br/>
      <div>
        <AreaChart
            width={700}
            height={400}
            data={data}  
        >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={coordenadaX} />
        <YAxis />
        <Tooltip />
        <Area
          type='linear'
          dataKey={dataKeyValue}
          stroke='#8884d8'
          fill='#8884d8'
        />
      </AreaChart>
      </div>
    </Form>
  </div>
  );
}

export default ChartReport;