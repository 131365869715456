import Amplify, { Auth } from "aws-amplify";

export const amplifyConfig = () => {
  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: 'us-east-2',
      userPoolId: 'us-east-2_DqDicB8dD',
      userPoolWebClientId: '60s6l2jkra5if6a19hbtaph48l',
      identityPoolId: 'us-east-2:22f9df55-e99b-44c9-ad07-47537010284b',
    },
    API: {
      endpoints: [
        {
          name: 'client',
          endpoint: 'https://70a2ln3z6b.execute-api.us-east-2.amazonaws.com/prod',
          region: 'us-east-2',
        }
      ]
    }
  });
  Auth.configure();
}