import React from "react";
import { Link } from 'react-router-dom';

  function Sidebar(props) {

    const handleLogout = () => {
        props.history.push('/login');
    }

    return (
            <div className="menu">
              <ul>
                <li><Link to="/registro" style={{ textDecoration: 'none', color: '#b8b7ad', fontWeight: 700, fontFamily: 'monospace'}}>Registrar Huésped</Link></li>
                <li><Link to="/reporte" style={{ textDecoration: 'none', color: '#b8b7ad', fontWeight: 700, fontFamily: 'monospace'}}>Consultar Alquileres</Link></li>
                <li><Link to="/ventas" style={{ textDecoration: 'none', color: '#b8b7ad', fontWeight: 700, fontFamily: 'monospace'}}>Vender Productos</Link></li>
              </ul>
            </div>
    );
  }
  export default Sidebar;
