import React, { useState, useEffect } from "react";
import { removeUserSession } from './Utils/Common';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useFormFields } from "./Utils/hooksLib";
import "./Client.css";
import { API } from 'aws-amplify';

import Menu from "./Utils/Sidebar";
import Popup from "reactjs-popup";
import BurgerIcon from "./Utils/BurgerIcon";

function Ventas(props) {

  const [clientes, setClientes] = useState([]);
  const [tipoProductos, setTipoProductos] = useState([]);
  const [productos, setProductos] = useState([]);
  const [precioProd, setPrecioProducto] = useState(0);
  const [precioTotal, setPrecioTotal] = useState('0.00');
  const [cantidadProductos, setcantidadProductos] = useState('');
  const [lstProductos, setLstProductos] = useState([]);

  const [fields, handleFieldChange] = useFormFields({
    cantidad: "",
    precio: "",
    fechaVenta: "",
    cliente: "",
    tipoProducto: "",
    nombreProducto: ""
  });

  useEffect(() => {

    const apiname = 'client';
    const path = '/hostal/api/listar-tipo-productos';

     API.get(apiname, path)
         .then(function (response) {
             console.log(response); 
             const tipos = response.map((tipo) => ({label: tipo.tipoProducto, value: tipo.tipoProducto}));     
             setTipoProductos(tipos);
             consultarProductosPorTipo({"target": {"value": response[0].tipoProducto}});
         })
         .catch(function (error) {
             alert('ERROR : ', error);
             console.log(error);
         })
         .then(function () {
         }); 

  }, []); 

  const consultarClienteByFecIng = (event) =>{
    // event.preventDefault();
    handleFieldChange(event);
    const apiname = 'client';
    const path = '/hostal/api/cliente/fecha/' + event.target.value.substring(0,10);

     API.get(apiname, path)
         .then(function (response) {
             console.log(response); 
             const users = response.map((user) => ({label: user.dni + ' - '+ user.nombres + ' '+ user.apellidoPaterno + ' '+ user.apellidoMaterno, value: user.idCliente}));     
             setClientes(users);
         })
         .catch(function (error) {
             alert('ERROR : ', error);
             console.log(error);
         })
         .then(function () {
         }); 
   //  }
   }

   const consultarProductosPorTipo = (event) =>{
    handleFieldChange(event);
    const apiname = 'client';
    const path = '/hostal/api/listar-productos-por-tipo/' + event.target.value;

    API.get(apiname, path)
         .then(function (response) {
             console.log(response); 
             const prod = response.productos.map((producto) => ({label: producto.nombreProducto, value: producto.idProducto})); 
             setLstProductos(response);    
             setProductos(prod);
             setPrecioProducto(response.productos[0].precioProducto);
             setcantidadProductos('');
             setPrecioTotal("0.00");             
             
         })
         .catch(function (error) {
             alert('ERROR : ', error);
             console.log(error);
         })
         .then(function () {
         }); 
   //  }
   
   }

  const [loading, setLoading] = useState(false);

  const handleLogout = () => {
    removeUserSession();
    props.history.push('/login');
  }

  const calcularPrecio = (event) => {
    setPrecioTotal(precioProd * event.target.value);
  }  

  const handleCantidadProductosChange = (event) => {
    setcantidadProductos(event.target.value);
  }  

  const getPrecio = (event) => {
    handleFieldChange(event);
    setcantidadProductos('');
    setPrecioTotal("0.00");
    lstProductos.productos.map((producto) => {
        if(producto.idProducto == event.target.value){
          setPrecioProducto(producto.precioProducto);
        }
    }); 
}

  async function saveVenta (event) {

    event.preventDefault();    
    setLoading(true);
    const apiname = 'client';
    const path = '/hostal/api/registrar-venta';

    const req ={ 
      "request": {
          "venta": {
            "cantidad": event.target[4].value,
            "precio": event.target[5].value,
            "idProducto": event.target[3].value,
            "idCliente": event.target[1].value,
            "fechaVenta": event.target[0].value
          }
      }
    };
   
    const payload = {
      body: req,
    };

    fields.cantidad = "";
    setPrecioTotal('0.00');
    
    await API.post(apiname, path, payload).then(function(res) {  
      alert('Registro exitoso '); 
      setLoading(false); 
    })
    .catch(function(err) {
      setLoading(false);
      console.log(err);
      alert('Se produjo un error registrando la venta, volver a intentar');
      return false;
    })
    .then(function() {
      setLoading(false);
    });
    
  }

  const contentStyle = {
    position: "fixed",
    transition: "all 0.5s ease 0s"
  };

  return (
   
    <div className="Login">
     
     <Popup
        modal
        overlayStyle={{ background: "#373a47", right: "inherit", width: "300px", height: "100%",}}
        contentStyle={contentStyle}
        closeOnDocumentClick={false}
        trigger={open => <BurgerIcon open={open} />}
     >
        {close => <Menu close={close} />}
    </Popup>

     <div id="loader" style={{ display: loading ? 'block' : 'none' }}></div>
    <div className='text-center'>

              <h3>
                <strong style={{color: '#e91e63'}}>Registro de</strong>
                <a href='#!'>
                  <strong> Ventas</strong>
                </a>
              </h3>
              <br />
    <div className='text-center'>
                <h3 style={{color: '#e91e63'}}>
                  <strong></strong>
                </h3>
              </div>
     
    </div>
    <Form onSubmit={saveVenta}>      
      <Form.Group size="lg" controlId="fechaVenta">
        <Form.Label>Fecha Venta</Form.Label>
        <Form.Control
          type="datetime-local"
          value={fields.fechaVenta}
          onChange={consultarClienteByFecIng}
          required
        />
      </Form.Group>
      <Form.Group size="lg" controlId="cliente">
        <Form.Label>Cliente</Form.Label>
        <Form.Control as="select" value={fields.cliente} onChange={handleFieldChange} required selected>
        {clientes.map((option) => (<option value={option.value} key={option.value}>{option.label}</option> ))}
        </Form.Control>
      </Form.Group>
      
      <Form.Group size="lg" controlId="tipoProducto">        
        <div className='col_half'>
        <Form.Label>Tipo Producto</Form.Label>
        <Form.Control as="select" value={fields.tipoProducto} onChange={consultarProductosPorTipo} required>
          {tipoProductos.map((option) => (<option value={option.value} key={option.value}>{option.label}</option> ))}
        </Form.Control>
        </div>
      </Form.Group>
      <Form.Group size="lg" controlId="nombreProducto">
        <div className='col_half'>
        <Form.Label>Producto</Form.Label>
        <Form.Control as="select" value={fields.nombreProducto} onChange={getPrecio} required>
          {productos.map((option) => (<option value={option.value} key={option.value}>{option.label}</option> ))}
        </Form.Control>
        <br/>
        </div>
      </Form.Group>

      <Form.Group size="lg" controlId="cantidad">
        <div className='col_half'>
        <Form.Label>Cantidad</Form.Label>
        <Form.Control
          type="text"
          value={cantidadProductos}
          placeholder={"0"}
          onChange={handleCantidadProductosChange}
          onBlur={calcularPrecio}
          required
        />
        </div>
      </Form.Group>
      <Form.Group size="lg" controlId="precio">
        <div className='col_half'>
        <Form.Label>Total S/</Form.Label>
        <Form.Control
          type="text"
          value={precioTotal}
          placeholder={"0.00"}
          onChange={handleFieldChange} 
          disabled
          required
        />
        <br/>
        </div>
        
      </Form.Group>
      
      <br/>
      <Button block size="lg" type="submit">
        Registrar
      </Button>
      <br/>
      <Button block size="lg" type="button" className='btn-rounded' onClick={handleLogout}>
        Salir
      </Button>
    </Form>
  </div>
  );
}

export default Ventas;
