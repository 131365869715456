import React, { useState, useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import Registro from './Registro';
import Login2 from './Login';
import Reporte from './Reporte';
import Ventas from './Ventas';
import ChartReport from './ChartReport';

import PrivateRoute from './Utils/PrivateRoute';
import PublicRoute from './Utils/PublicRoute';
import { getUser, removeUserSession, setUserSession } from './Utils/Common';
import { amplifyConfig } from './Utils/Config';
import "./App.css";
import "./Menu.css";

function App() {
  const [authLoading, setAuthLoading] = useState(true);

  useEffect(() => {
    amplifyConfig();
    Auth.currentAuthenticatedUser({ bypassCache: true })
      .then(user => {
        setUserSession(user);
        setAuthLoading(false);
      })
      .catch(error => {
        removeUserSession();
        setAuthLoading(false);

        return;
      });
  }, []); 

  if (authLoading && getUser()) {
    return <div className="content">Checking Authentication....</div>
  }

  return (
    <div className="App">  
      <BrowserRouter>
        <div>
          <div className="content">
            <Switch>
              <Route exact path="/" component={Login2} />
              <PublicRoute path="/login" component={Login2} />
              <PrivateRoute path="/reporte" component={Reporte} />
              <PrivateRoute path="/registro" component={Registro} />
              <PrivateRoute path="/ventas" component={Ventas} />
              <PrivateRoute path="/chart" component={ChartReport} />
            </Switch>
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
