// return the token from the session storage
export const getUser = () => {
  // return sessionStorage.getItem('token') || null;
  return sessionStorage.getItem('user') || null;
}

// remove the token and user from the session storage
export const removeUserSession = () => {
  localStorage.clear();
  sessionStorage.removeItem('user');
}

// set the token and user from the session storage
// export const setUserSession = (token, user) => {
  export const setUserSession = ( user) => {
  // sessionStorage.setItem('token', token);
  sessionStorage.setItem('user', JSON.stringify(user));
}