import React, { useState } from "react";
import { Auth } from "aws-amplify";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useFormFields } from "./Utils/hooksLib";
import { setUserSession } from './Utils/Common';
import "./Login.css";

export default function Login(props) {
  const [loading, setLoading] = useState(false);

  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
  });

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  async function handleLogin (event) {
    event.preventDefault();
    setLoading(true);
    await Auth.signIn(fields.email, fields.password)
        .then(user => {
          console.log(user);
          setLoading(false);
          setUserSession(user);
          props.history.push('/registro');
        })
        .catch(err => {
          setLoading(false);
          console.log(err)
        })
  }

  return (
    <div className="Login">
      <div id="loader" style={{ display: loading ? 'block' : 'none' }}></div>
      <div className='text-center'>
                <h3 className='white-text mb-5 mt-4 font-weight-bold'>
                  <strong>SIGN</strong>
                  <a href='#!' className='green-text font-weight-bold'>
                    <strong> IN</strong>
                  </a>
                </h3>
      </div>
      <Form onSubmit={handleLogin}>
        <Form.Group size="lg" controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Form.Group size="lg" controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Button
          block
          size="lg"
          type="submit"
          disabled={loading || !validateForm()}
        >
          Login
        </Button>
      </Form>
    </div>
  );
}
