import React, { useState } from "react";
import { removeUserSession } from './Utils/Common';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useFormFields } from "./Utils/hooksLib";
import "./Client.css";
import { API } from 'aws-amplify';

import Menu from "./Utils/Sidebar";
import Popup from "reactjs-popup";
import BurgerIcon from "./Utils/BurgerIcon";

function Registro(props) {

  const [loading, setLoading] = useState(false);

  const [fields, handleFieldChange] = useFormFields({
    dni: "",
    nombres: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    habitacion: "",
    precio: "",
    fechaIngreso: ""
  });
  const [transferencia, setTransferencia] = useState(false);

  const handleLogout = () => {
    removeUserSession();
    props.history.push('/login');
  }

  const reporte = () => {
    props.history.push('/reporte');
  }

  async function changeCheckValue (event) {
    setTransferencia(event.target.checked);
  }

  async function saveClient (event) {
    event.preventDefault();    
    setLoading(true);
    const apiname = 'client';
    const path = '/hostal/api/registrar-cliente';

    const req ={ 
      "request": {
        "cliente": {
          "dni":fields.dni,
          "nombres": fields.nombres,
          "apellidoPaterno": fields.apellidoPaterno,
          "apellidoMaterno": fields.apellidoMaterno,
          "numeroHabitacion": fields.habitacion,
          "precioHabitacion": fields.precio,
          "fechaIngreso": fields.fechaIngreso,
          "tranferencia": transferencia
        }
      }
    };

    const payload = {
      body: req,
    };

    fields.dni = "";
    fields.nombres = "";
    fields.apellidoPaterno = ""; 
    fields.apellidoMaterno = "";
    fields.habitacion = "";
    fields.precio = "";
    fields.fechaIngreso = "";
    setTransferencia(false);

    await API.post(apiname, path, payload).then(function(res) {  
      alert('Registro exitoso '); 
      setLoading(false); 

    })
    .catch(function(err) {
      setLoading(false);
      console.log(err);
      alert('Se produjo un error registrando cliente, volver a intentar');
      return false;
    })
    .then(function() {
      setLoading(false);
    });

}


async function consultarCliente(event){
  event.preventDefault();    
  const apiname = 'client';
  const path = '/hostal/api/cliente/' + fields.dni;

   if(fields.dni !== undefined && fields.dni.length === 8){
    setLoading(true);
    await API.get(apiname, path)
       .then(function (response) {
           console.log(response);
           console.log(response.cliente.length);
           if(response.cliente.length > 0){
              fields.nombres = response.cliente[0].nombres;
              fields.apellidoPaterno = response.cliente[0].apellidoPaterno;
              fields.apellidoMaterno = response.cliente[0].apellidoMaterno;
           }
       })
       .catch(function (error) {
           // handle error
           setLoading(false);
           console.log(error);
       })
       .then(function () {
           // always executed
           setLoading(false);
       });
   }
 }

 const contentStyle = {
  position: "fixed",
  transition: "all 0.5s ease 0s"
};

  return (
    <div className="Login">
     <Popup
        modal
        overlayStyle={{ background: "#373a47", right: "inherit", width: "300px", height: "100%",}}
        contentStyle={contentStyle}
        closeOnDocumentClick={false}
        trigger={open => <BurgerIcon open={open} />}
     >
        {close => <Menu close={close} />}
    </Popup>
     <div id="loader" style={{ display: loading ? 'block' : 'none' }}></div>
    <div className='text-center'>

              <h3>
                <strong style={{color: '#e91e63'}}>Registro de</strong>
                <a href='#!'>
                  <strong> Huéspedes</strong>
                </a>
              </h3>
              <br />
    <div className='text-center'>
                <h3 style={{color: '#e91e63'}}>
                  <strong></strong>
                </h3>
              </div>
     
    </div>
    <Form onSubmit={saveClient}>
      <Form.Group size="lg" controlId="dni">
        <div className='col_half'>
        <Form.Label>DNI</Form.Label>
        <Form.Control
          type="text"
          value={fields.dni}
          onChange={handleFieldChange}
          pattern="[0-9]{8}"
          required
          onBlur={consultarCliente}
        />
        </div>
      </Form.Group>
      <Form.Group size="lg" controlId="nombres">
        <div className='col_half'>
        <Form.Label>Nombres</Form.Label>
        <Form.Control
          autoFocus
          type="text"
          value={fields.nombres}
          onChange={handleFieldChange} 
          required
        />
        </div>
      </Form.Group>

      <Form.Group size="lg" controlId="apellidoPaterno">
        <div className='col_half'>
        <Form.Label>Ape. Paterno</Form.Label>
        <Form.Control
          type="text"
          value={fields.apellidoPaterno}
          onChange={handleFieldChange}
          required
        />
        </div>
      </Form.Group>
      <Form.Group size="lg" controlId="apellidoMaterno">
        <div className='col_half'>
        <Form.Label>Ape. Materno</Form.Label>
        <Form.Control
          type="text"
          value={fields.apellidoMaterno}
          onChange={handleFieldChange}
          required
        />
        </div>
      </Form.Group>

      <Form.Group size="lg" controlId="habitacion">
        <div className='col_half'>
        <Form.Label>Habitacion</Form.Label>
        <Form.Control
          type="text"
          value={fields.habitacion}
          onChange={handleFieldChange}
          required
        />
        </div>
      </Form.Group>
      <Form.Group size="lg" controlId="precio">
        <div className='col_half'>
        <Form.Label>Precio</Form.Label>
        <Form.Control
          type="text"
          value={fields.precio}
          onChange={handleFieldChange} 
          required
        />
        </div>
      </Form.Group>

      <Form.Group size="lg" controlId="fechaIngreso">
        <Form.Label>Fecha Ingreso</Form.Label>
        <Form.Control
          type="datetime-local"
          value={fields.fechaIngreso}
          onChange={handleFieldChange}
          required
        />
      </Form.Group>
      <Form.Group size="lg" controlId="transferencia">
        <Form.Check
          value={transferencia}
          type="checkbox"
          label="Yape/Plim"
          onChange={changeCheckValue}
          checked={transferencia}
      />
      </Form.Group>
      <Button block size="lg" type="submit">
        Registrar
      </Button>
      <br/>
      <Button block size="lg" type="button" className='btn-rounded' onClick={handleLogout}>
        Salir
      </Button>
    </Form>
  </div>
  );
}

export default Registro;
